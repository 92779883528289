@import 'src/assets/styles/common';

.feature-item {
  @include flex(center, space-between);
  width: 100%;
  max-width: 1440px;

  margin-left: auto;
  margin-right: auto;

  --feature-item-gap: 6.6%;

  gap: var(--feature-item-gap);

  @include beyond-desktop {
    --feature-item-gap: 120px;
  }

  @include mobile {
    flex-direction: column;

    --feature-item-gap: 0;
  }
}

.feature-content {
  @include flex-fixed;
  flex: 1;

  @include mobile {
    @include flex(center);
    flex-direction: column;

    margin-bottom: 80px;
  }
}


.feature-category {
  @include font-barlow(16px, 18px, $font-medium);
  color: $blue-3;

  margin-bottom: 4px;

  &.__extra {
    color: $orange-3;
  }

  @include mobile {
    @include font-barlow(16px, 18px, $font-medium);

    text-align: center;
  }
}

.feature-title {
  @include font-barlow(40px, 50px, $font-black);
  color: $gray-1;
  width: 100%;

  margin-bottom: 18px;

  @include mobile {
    @include font-barlow(36px, 38px, $font-black);

    text-align: center;

    margin-bottom: 16px;
  }
}

.feature-text {
  @include font-lato(18px, 26px);
  color: $gray-6;

  margin-bottom: 18px;

  @include mobile {
    text-align: center;

    margin-bottom: 24px;
  }
}

.feature-actions {
  .btn {
    display: inline-block;
  }

  @include mobile {
    @include flex(center, center);
    width: 100%;

    .btn {
      width: 265px;
      text-align: center;
    }
  }
}

.feature-figure {
  @include flex-fixed;
  flex: 1;

  position: relative;

  //@include mobile {
  //  width: 100%;
  //}
}

.feature-item.__light {
  .feature-category {
    color: $blue-7;
  }
  .feature-title {
    @include font-barlow(56px, 62px, $font-black);
    color: $blue-9;
  }
  .feature-text {
    @include font-lato(18px, 32px, $font-medium);
    color: $gray-7;
  }
}

.feature-item.__flip {
  .feature-content {
    order: 2;

    @include mobile {
      order: unset;
    }
  }
}
