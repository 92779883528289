$default-header-height: 101px;
$default-footer-height: 250px;
$default-footer-bottom-height: 35px;
$default-horizontal-padding: 20px;
$default-vertical-padding: 20px;

$font-black: 800;
$font-bold: 700;
$font-semi-bold: 600;
$font-medium: 500;
$font-regular: 400;
$font-light: 300;

$font-family-sfpro: 'SF Pro Display', sans-serif;
$font-family-barlow: 'Barlow', sans-serif;
$font-family-lato: 'Lato', sans-serif;
$font-family-nanum-pen: 'Nanum Pen Script', cursive;

